import axios from "axios"
import { useEffect, useState } from "react"
import LinkStatItem from "../Components/LinkStatItem"


const LinkStat = () => {
    const [influencers,setInfluencers] = useState(null)

    const getList = async() => {
      setInfluencers(await axios.get('https://api.steam-rent.com/influencersList').then(resp => resp.data.influencers))
    }

    useEffect(() => {
        getList()
    },[])
    return(
        <div className="linkstat-page">
            <div className="linkstat-page__head">

            </div>
            {influencers && influencers.map(item => 
                <LinkStatItem data={item}/>
            )}
        </div>
    )
}


export default LinkStat