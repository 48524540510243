import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewGame } from "../store/action-creators/gamesActions";
import CheckBoxCustom from "./UI/CheckBoxCustom";

const NewGameModal = ({ visibility, setVisibility }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [rate, setRate] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [img, setImg] = useState("");
  const [description, setDescription] = useState("");
  const [priceT, setPriceT] = useState("");
  const [priceUsd, setPriceUsd] = useState("");
  const [priceU, setPriceU] = useState("");
  const [platform,setPlatform] = useState('Steam')
  const [language,setLanguage] = useState('Субтитры')
  const [releaseDate,setReleaseDate] = useState('')
  const [publisher,setPublisher] = useState('')
  const [screenshots,setScreenshots] = useState([''])
  const [video,setVideo] = useState('')
  const [auto,setAuto] = useState(false)
  const [genre,setGenre] = useState('')

  const add = () => {
    setVisibility(!visibility);
    const newGame = {
      title,
      description,
      video,
      img,
      screenshots,
      isOnline,
      login,
      pass,
      rate,
      platform,
      publisher,
      releaseDate,
      genre,
      language,
      auto,
      genre,
      priceT,
      priceU,
      priceUsd,
    };
    dispatch(addNewGame(newGame)); 
  };

  const delScreen = (i) =>{
    setScreenshots(prev => prev.filter((_, index) => index !== i))
  }

  return (
    <div
      onClick={() => setVisibility(!visibility)}
      style={visibility ? { zIndex: 1 } : { opacity: 0 }}
      className="modal"
    >
      <div onClick={(e) => e.stopPropagation()} className="modal-content">
                <div className="input-container">
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          type="text"
          placeholder="Title"
        />
        </div>
                <div className="input-container">

        <input
          value={rate}
          onChange={(e) => setRate(e.target.value)}
          type="text"
          placeholder="Rate"
        />
        </div>
        <div className="modal-content-status-wrapper">
          <div id={!isOnline ? 'modal-content-status-choice-selected' : ''} onClick={() => setIsOnline(false)} className="modal-content-status-choice">Offline</div>
          <div id={isOnline ? 'modal-content-status-choice-selected' : ''} onClick={() => setIsOnline(true)} className="modal-content-status-choice">Online</div>
        </div>
        <div className="modal-content-status-wrapper">
          <div id={platform == 'Steam' ? 'modal-content-status-choice-selected' : ''} onClick={() => setPlatform('Steam')} className="modal-content-status-choice">Steam</div>
          <div id={platform == 'EGS' ? 'modal-content-status-choice-selected' : ''} onClick={() => setPlatform('EGS')} className="modal-content-status-choice">EpicGames</div>
        </div>
              <div className="input-container">
                  <input
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                    type="text"
                    placeholder="Login"
                  />
              </div>
      <div className="input-container">
          <input
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            type="text"
            placeholder="Pass"
          />
        </div>

      

        <div className="modal-content-status-wrapper">
            <div id={language == 'Английский' ? 'modal-content-status-choice-selected' : ''} onClick={() => setLanguage('Английский')} className="modal-content-status-choice">Английский</div>
            <div id={language == 'Субтитры' ? 'modal-content-status-choice-selected' : ''} onClick={() => setLanguage('Субтитры')} className="modal-content-status-choice">Субтитры</div>
            <div id={language == 'Озвучка' ? 'modal-content-status-choice-selected' : ''} onClick={() => setLanguage('Озвучка')} className="modal-content-status-choice">Озвучка</div>
        </div>

        <div className="input-container">
        <input
          value={img}
          onChange={(e) => setImg(e.target.value)}
          type="text"
          placeholder="img"
        />
        </div>
        <div className="modal-content-rating-wrapper">
          <div style={{marginRight:'15px'}} className="input-container">
            <input
                value={releaseDate}
                onChange={(e) => setReleaseDate(e.target.value)}
                type="text"
                placeholder="Release date"
            />
        </div>
        <div className="input-container">
            <input
                value={publisher}
                onChange={(e) => setPublisher(e.target.value)}
                type="text"
                placeholder="Publisher"
            />
        </div>
      </div>
      <div className="input-container">
            <input
                value={genre}
                onChange={(e) => setGenre(e.target.value)}
                type="text"
                placeholder="Genres"
            />
        </div>
        
       
        <div id={screenshots.length > 1 ? 'screens-box':''} className="modal-content-screens-wrapper">
            {screenshots.map((item, i) =>
            <div id="input-container-screen" className="input-container">
              <input
                  value={item}
                  onChange={(e) =>  setScreenshots(prev => 
                    prev.map((item, ix) => (ix === i ? e.target.value : item)))}
                  type="text"
                  placeholder={`Screen ${i+1}`}
              />
              <div  className="add-screen-btn">...</div>
              <div className="screens-modal">
                  <div onClick={() => setScreenshots(prev => [...prev,''])} className="screens-modal-option">ADD</div>
                 {i !== 0 &&  <div onClick={() => delScreen(i)} className="screens-modal-option">DELL</div>}
              </div>
          </div>

            )}
        </div>

        <div className="input-container">
            <input
                value={video}
                onChange={(e) => setVideo(e.target.value)}
                type="text"
                placeholder="Video"
            />
        </div>

        <div className="modal-content-rating-wrapper">
        {/* <div className="input-container">
              <input
                  value={video}
                  // onChange={(e) => setVideo(e.target.value)}
                  type="text"
                  placeholder="MetaCritic"
                  id="input-container-short"

              />
          </div> */}

        </div>

        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={10}
          placeholder="Description"
        />
        <div className="prices">
          <input
            value={priceU}
            onChange={(e) => setPriceU(e.target.value)}
            placeholder="₴"
            type="text"
          />
          <input
            value={priceT}
            onChange={(e) => setPriceT(e.target.value)}
            placeholder="₸"
            type="text"
          />
          <input
            value={priceUsd}
            onChange={(e) => setPriceUsd(e.target.value)}
            placeholder="$"
            type="text"
          />
        </div>
        <button onClick={add}>OK</button>

        <div className="modal-auto">
          <CheckBoxCustom setState={setAuto} state={auto} />  
        </div>
      </div>
    </div>
  );
};

export default NewGameModal;
