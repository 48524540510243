import axios from "axios";
import { taskActionTypes } from "../reducers/tasksReducer";

export const fetchTasks = () => {
  return async (dispatch) => {
    dispatch({ type: taskActionTypes.FETCH_TASKS });
    try {
      const tasks = await axios
        .get("https://api.steam-rent.com/plan")
        .then((resp) => resp.data);
      tasks.sort((a, b) => new Date(a.date) - new Date(b.date));
      dispatch({ type: taskActionTypes.FETCH_TASKS_SUCCESS, payload: tasks });
    } catch (e) {
      dispatch({ type: taskActionTypes.FETCH_TASKS_ERROR });
      console.log(e);
    }
  };
};

export const refresh = () => {
  return async (dispatch) => {
    try {
      const tasks = await axios
        .get("https://api.steam-rent.com/plan")
        .then((resp) => resp.data);
      dispatch({ type: taskActionTypes.FETCH_TASKS_SUCCESS, payload: tasks });
    } catch (e) {
      dispatch({ type: taskActionTypes.FETCH_TASKS_ERROR });
    }
  };
};
