const initialState = {
  tasks: [],
  isLoading: false,
  error: false,
};

export const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case taskActionTypes.FETCH_TASKS:
      return { ...state, isLoading: true };
    case taskActionTypes.FETCH_TASKS_ERROR:
      return { ...state, error: true, isLoading: false };
    case taskActionTypes.FETCH_TASKS_SUCCESS:
      return { ...state, isLoading: false, tasks: action.payload };

    default:
      return state;
  }
};

export const taskActionTypes = {
  FETCH_TASKS: "FETCH_TASKS",
  FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
  FETCH_TASKS_ERROR: "FETCH_TASKS_ERROR",
};
