import React from "react";
import Line from "../Components/Statistics/Line";
import Pie from "../Components/Statistics/Pie";
import RecomendationsBlck from "../Components/RecomendationsBlck";
import ReviewsBlock from "../Components/ReviewsBlock";
import Boxes from "../Components/Statistics/Boxes";

const StatisticsPage = () => {
  return (
    <div className="statistics-page">
      <Boxes />
      <Pie />
      <Line />
      <div className="statistics-page__row">
        <RecomendationsBlck />
        <ReviewsBlock />
      </div>
    </div>
  );
};

export default StatisticsPage;
