import axios from "axios"
import { useEffect, useState } from "react"
import Loader from './UI/Loader'


const PaymentItemUserPayments = ({userEmail}) => {

    const [payments,setPayments] = useState(null)
    const [moneySpent,setMoneySpent] = useState(0)
    const [cur,setCur] = useState('')

    const getUserPaymetns = async () => {
    try{
        console.log('get user data')
        // axios.post('https://api.steam-rent.com/admin/getUserPaymets',{userEmail:userEmail}).then(resp => setPayments(resp.data))
       const data =  await  axios.post('https://api.steam-rent.com/admin/getUserPaymets',{userEmail:userEmail}).then(resp => resp.data)
       console.log(data)
       setPayments(data.payments)
       setMoneySpent(data.moneySpent)
       setCur(data.currency)

    }catch(e){
        console.log('get user data err')
    }
    }

    useEffect(() => {
        getUserPaymetns()
    },[])

    if(payments == null){
            <Loader/>
    }
    return(
        <div 
        onClick={() => console.log(payments)}
        className="payment-item__userpyaments">
          {payments ? 
           <>
           {payments &&
               payments.map((item,i) => 
                   <div className="payment-item__userpyaments-item">
                       <p>{++i} {' '} {item.title}</p> <p className="datee">{item.date}</p>
                   </div>
               )
               }
               <div className="payment-item__userpyaments_inf">
               <span>{moneySpent.toFixed(2)} {cur}</span>
               </div>
           </>
           :
           <Loader/>
          }
        </div>
    )
}

export default PaymentItemUserPayments