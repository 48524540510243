import React, { useState } from "react";
import { useSelector } from "react-redux";
import PaymentItem from "./PyamentItem.jsx";
import Loading from "./UI/LoadingAnim.jsx";

const PaymentsList = () => {
  const { isLoading, sortedPayments } = useSelector((state) => state.payment);
  if (isLoading) {
    return <Loading />;
  }

  return sortedPayments.map((item, i) => (
    <>
      <PaymentItem
        i={i}
        sortedPayments={sortedPayments}
        key={item._id}
        item={item}
      />
    </>
  ));
};

export default React.memo(PaymentsList);
