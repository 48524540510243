import "./App.scss";
import Menu from "./Components/Menu";
import PaymentsPage from "./Pages/PaymentsPage";
import { Routes, Route } from "react-router-dom";
import GamesPage from "./Pages/GamesPage";
import StatisticsPage from "./Pages/StatisticsPage";
import { useState } from "react";
import AuthPage from "./Pages/AuthPage";
import { useSelector } from "react-redux";
import UsersPage from "./Pages/UsersPage";
import PlanPage from "./Pages/PlanPage";
import GuardsPage from "./Pages/GuardsPage";
import TransactionsPage from "./Pages/TransactionsPage";
import LinkStat from "./Pages/LinkStat";
import GGStatPage from "./Pages/GGStatPage";

function App() {
  const { isAuth } = useSelector((state) => state.auth);

  if (!isAuth) {
    return <AuthPage />;
  }

  return (
    <div className="App">
      <Menu />
      <div className="container">
        <Routes>
          <Route path="/" element={<StatisticsPage />} />
          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/plan" element={<PlanPage />} />
          <Route path="/guards" element={<GuardsPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/linkstat" element={<LinkStat />} />
          <Route path="/ggstat" element={<GGStatPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
