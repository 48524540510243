import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios";

const Line = () => {
  const [income4line, setIncome4Line] = useState([
    { data: [{ id: 0 }], id: 0 },
  ]);
  const [timeLine, setTimeLine] = useState(28);
  const [what, setWhat] = useState("income");
  const getData = async () => {
    try {
      setIncome4Line(
        await axios
          .post("https://api.steam-rent.com/getincomestatistics4line", {
            // .post("http://localhost:8080/getincomestatistics4line", {
            timeLine,
            what,
          })
          .then((resp) => resp.data)
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
    console.log(income4line);
  }, [timeLine, what]);
  return (
    <div className="statistics__line__container">
      <div className="statistics__line">
        <ResponsiveLine
          data={income4line}
          curve="catmullRom"
          margin={{ top: 75, right: 50, bottom: 35, left: 37 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          axisBottom={{
            orient: "bottom",
            tickPadding: 5,
            tickSize: 10,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "count",
            legendOffset: -40,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={false}
          lineWidth={3}
          pointSize={7}
          pointColor={{ theme: "background" }}
          pointBorderWidth={3}
          pointBorderColor={{ from: "serieColor", modifiers: [] }}
          pointLabelYOffset={-10}
          enableArea={true}
          areaBlendMode="hard-light"
          areaOpacity={0.012}
          useMesh={true}
        />
        <div
          onClick={() => (timeLine == 28 ? setTimeLine(7) : setTimeLine(28))}
          className="timeline"
        >
          {timeLine == 28 ? <p>Month</p> : <p>Week</p>}
        </div>
        <div
          onClick={() =>
            what == "users" ? setWhat("income") : setWhat("users")
          }
          className="timeline"
        >
          {what != "income" ? <p>Users</p> : <p>Income</p>}
        </div>
      </div>
    </div>
  );
};

export default Line;
