import axios from "axios";
import { gamesActions } from "../reducers/gamesReducer";

export const fetchGames = () => {
  return async (dispatch) => {
    dispatch({ type: gamesActions.FETCH_GAMES });
    try {
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.FETCH_GAMES_SUCCESS, payload: resp.data });
    } catch (e) {
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const addLock = (duration, id) => {
  console.log(duration, id);
  axios
    .post("https://api.steam-rent.com/lockgame", { duration, id })
    .then((resp) => console.log(resp));
};

export const update = () => {
  return async (dispatch) => {
    try {
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const changeGameRating = (newRate, id) => {
  return async (dispatch) => {
    try {
      await axios.post("https://api.steam-rent.com/changerate", {
        newRate,
        id,
      });
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const changeOnlineStatus = (status, id) => {
  status = status.toLowerCase();
  return async (dispatch) => {
    try {
      await axios.post("https://api.steam-rent.com/changestatus", {
        status,
        id,
      });
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const changeGameTitle = (newTitle, id) => {
  return async (dispatch) => {
    if (!window.confirm(`game will be renamed to ${newTitle}`)) {
      return;
    }
    try {
      await axios.post("https://api.steam-rent.com/renamegame", {
        newTitle,
        id,
      });
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const changeGamePrice = (
  duration,
  currency,
  newPrice,
  id,
  previousPrices,
  dgsIDs,
  _id
) => {
  return async (dispatch) => {
    try {
      await axios.post("https://api.steam-rent.com/changeprice", {
        duration,
        currency,
        id,
        newPrice,
        previousPrices,
      });
      if (currency == "$") {
        if (dgsIDs == undefined) {
          dgsIDs = {
            m: 0,
            w: 0,
            d: 0,
          };
        }
        await axios
          .post("https://api.steam-rent.com/changeDgsPrice", {
            // .post("http://localhost:8080/changeDgsPrice", {
            newPrice,
            duration,
            dgsIDs,
            _id,
          })
          .then((resp) => (resp.data !== true ? alert(resp.data) : ""));
      }
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const addNewGame = (newGame) => {
  return async (dispatch) => {
    try {
      console.log(newGame);

      await axios.post("https://api.steam-rent.com/addnewgame", {
        // await axios.post("http://localhost:8080/addnewgame", {
        newGame,
      });
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      console.log(e);
      dispatch({ type: gamesActions.FETCH_GAMES_ERROR });
    }
  };
};

export const changeGamePass = (pass, id) => {
  console.log(pass, id);

  return async (dispatch) => {
    if (!window.confirm(`new pass: ${pass} ?`)) {
      return;
    }
    try {
      await axios
        .post("https://api.steam-rent.com/changepass", {
          id,
          pass,
        })
        .then((resp) =>
          resp.data.status ? "" : alert("error,Something went wrong")
        );
      // await axios
      //   .post("http://localhost:8080/changepass", {
      //     id,
      //     pass,
      //   })
      //   .then((resp) => console.log(resp));
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeGameLogin = (login, id) => {
  return async (dispatch) => {
    if (!window.confirm(`new login: ${login} ?`)) {
      return;
    }
    try {
      await axios
        .post("https://api.steam-rent.com/changeLg", {
          id,
          login,
        })
        .then((resp) =>
          resp.data.status ? "" : alert("error,Something went wrong")
        );
      // await axios
      //   .post("http://localhost:8080/changeLg", {
      //     id,
      //     login,
      //   })
      //   .then((resp) =>
      //     resp.data.status ? "" : alert("error,Something went wrong")
      //   );
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeGameVisivility = (id) => {
  return async (dispatch) => {
    try {
      const status = window.confirm("visible?");
      await axios.post("https://api.steam-rent.com/changeGameVisibility", {
        id,
        status,
      }).th;
      const resp = await axios.get("https://api.steam-rent.com/api/games");
      dispatch({ type: gamesActions.UPDATE, payload: resp.data });
    } catch (e) {
      console.log(e);
    }
  };
};
