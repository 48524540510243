import React from "react";
import { useSelector } from "react-redux";
import GameItem from "./GameItem.jsx";
import Loading from "./UI/LoadingAnim.jsx";
import UserItem from "./UserItem.jsx";
import { memo } from "react";

const UsersList = ({ filteredUsers }) => {
  const { isLoading } = useSelector((state) => state.user);

  if (isLoading) {
    return <Loading />;
  }

  return filteredUsers.map((user) => <UserItem key={user._id} user={user} />);
};

export default memo(UsersList);
