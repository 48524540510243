const initialState = {
  isAuth: false,
  error: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.logIn:
      return { ...state, isAuth: action.payload };
    case authActions.error:
      return { ...state, error: true };

    default:
      return state;
  }
};

export const authActions = {
  logIn: "logIn",
  error: "error",
};
