import axios from "axios"
import { useEffect, useState } from "react"
import GGStatDay from "../Components/GGStat/GGStatDay";
import GGProductItem from "../Components/GGStat/GGProductItem";


const GGStatPage = () => {
    const [side,setSide] = useState(1)
    const [GGSData,setGGSData] = useState(null)
    const [GGProducts,setGGProucts] = useState(null)
    const getData = async() => {
        console.log('get data')
        setGGSData(null)
        const stat = await axios.post('https://api.steam-rent.com/admin/getGGData').then(resp =>resp.data.GGStatData).catch(resp => console.log('error'))
        console.log(stat)
        setGGSData(prev => stat)
    }


    const getGGProducts = async() => {
        if(GGProducts && side == 1) return
        await axios.get('https://api.steam-rent.com/admin/getGGprod').then(resp => setGGProucts(resp.data.products))
    }


    
   

    useEffect(() => {
        getData()
    },[])
    return(
        <div className="ggstat-page">
            <div className="gg-header">
                <div onClick={() => {setSide(side == 1 ? 2 : 1);getGGProducts()}} className="gg-header__btn">Products</div>
            </div>
            {GGSData && side == 1 && GGSData.map((dayData) => 
                <GGStatDay dayData={dayData} update={getData}/>
            )}
            {/* ///////////////////////////////////// */}
            {GGProducts && side == 2 && GGProducts.map((productItem) => 
                <GGProductItem getGGProducts={getGGProducts} product={productItem}/>
            )}
        </div>
    )    
}


export default GGStatPage