import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Menu = () => {
  const [notif, setNotif] = useState(0);
  const [expiredLinksNotif,setExpiredLinksNotif] = useState(0)

  const getNotifications = async () => {
    try {
      const newNotif = await axios
        .get("https://api.steam-rent.com/adminNotification")
        .then((resp) => resp.data);
        console.log(newNotif)
      // if (localStorage.getItem("transactionNotifications") != newNotif.number) {
        if (localStorage.getItem("transactionNotifications") < newNotif.number) {
          playSound();
        }
        localStorage.setItem("transactionNotifications", newNotif.number);
        setExpiredLinksNotif(newNotif.expired)
        setNotif(newNotif.number);
      // }
    } catch (e) {
      console.log("getNotifications Error");
    }
  };

  const playSound = () => {
    const sound = new Audio(require("../assets/sound/coin-drop.mp3"));
    sound.play();
  };
  useEffect(() => {
    getNotifications();
    const intervalId =  setInterval(() => {
      getNotifications();
    }, 10000);

    return {
      clearInterval: () => clearInterval(intervalId),
    }
  }, []);

  return (
    <div onClick={() => console.log({notif,expiredLinksNotif})} id="menuH" classNeme="hamburger-menu">
      <input id="menu__toggle" type="checkbox" />
      <label className="menu__btn" htmlFor="menu__toggle">
        <span></span>
      </label>

      <ul className="menu__box">
        <li>
          <Link className="menu__item" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link className="menu__item" to="/payments">
            Payments
          </Link>
        </li>
        <li>
          <Link className="menu__item" to="/games">
            Games
          </Link>
        </li>

        <li>
          <Link className="menu__item" to="/users">
            Users
          </Link>
        </li>
        <li>
          <Link className="menu__item" to="/plan">
            Tasks
          </Link>
        </li>
        <li>
          <Link className="menu__item" to="/guards">
            Guards
          </Link>
        </li>
        {/* <li style={{ display: "flex" }}>
          <Link className="menu__item" to="/transactions">
            UALinks
          </Link>
          {notif > 0 && <div className="notifications">{notif}</div>}
        </li> */}
        <li>
          <Link className="menu__item" to="/linkstat">
            Links
          </Link>
        </li>
        <li>
          <Link className="menu__item" to="/ggStat">
            GG
          </Link>
        </li>
        <li style={{ display: "flex" }}>
          <Link className="menu__item" to="/transactions">
            UA
          </Link>
          {notif > 0 && <div className="notifications">{notif}</div>}
          {expiredLinksNotif.length > 0 && <div id="link-notif" className="notifications">{expiredLinksNotif.length}
            <div className="link-notif-sum">
            {expiredLinksNotif.length > 0 && expiredLinksNotif.map(expItem => <div className="link-notif-sum-item" style={{color:'wheat'}}>{expItem.sum}</div>)}  
            </div>
          </div>}
        </li>
      </ul>
    </div>
  );
};

export default Menu;
