import React from "react";

const CheckBoxCustom = ({ state, setState }) => {
  return (
    <div className="checkbox-wrapper-3">
      <input
        onChange={(e) => setState(!state)}
        value={false}
        type="checkbox"
        id="cbx-3"
      />
      <label htmlFor="cbx-3" className="toggle">
        <span></span>
      </label>
    </div>
  );
};

export default CheckBoxCustom;
