import { useEffect, useState } from "react"
import ClickInput from "../UI/ClickInput";
import axios from "axios";

const GGProductItem= ({product,getGGProducts}) => {
    const [commissionActive,setCommissionActive] = useState(false);
    const [loginActive,setLoginActive] = useState(false);
    const [isAuthOpen,setIsAuthOpen] = useState(false)
    // const [login,setLogin] = useState(null)
    // const [pass,setPass] = useState(null)

    const submitCommission = () => {
        setCommissionActive(false)
    }

    const setNewPass = async(newPass) => {
        await axios.post('http://localhost:8080/admin/changeGGpass',{GGID:product.GGProuctId,pass:newPass,id:product._id,newPass:newPass})
        getGGProducts()
    }


    const setNewLogin = async(newLogin) => {
        await axios.post('http://localhost:8080/admin/changeGGlogin',{GGID:product.GGProuctId,newLogin,id:product._id})
        getGGProducts()
    }   

    return(
        <div className="gg-product-item-wrp">
            <div className="gg-product-item">
            <span className="gg-product-item__title">{product.title}</span>
            <div className="gg-product-item__commission">
                {commissionActive ? <div className="input-wrp"><input className="plain-input"/> <span onClick={submitCommission}>k</span></div> : <span onClick={() => setCommissionActive(!commissionActive)}>{product.commission}%</span>}
            </div>
            <div onClick={() => setIsAuthOpen(!isAuthOpen)} className="gg-product-item__auth-btn"><img src={require("../../assets/img/keyicon.png")} alt="" /></div>
            {/* <div className="gg-product-item__income">{product.income}</div> */}
            </div>

           {isAuthOpen &&  <div className="gg-product-btm">
                <div className="btm-auth">
                    <div className="btm-item"><span>ID</span> <ClickInput setText={setNewLogin} text={product.OurProductId}/></div>
                    <div className="btm-item"><span>GG-ID</span>  <ClickInput setText={setNewLogin} text={product.GGProuctId}/> </div>
                        
                </div>
                <div className="btm-auth">
                    <div className="btm-item"><span>Login</span> <ClickInput setText={setNewLogin} text={product.login}/></div>
                    <div className="btm-item"><span>Pass</span>  <ClickInput setText={setNewPass} text={product.pass}/> </div>
                    
                </div>
            </div>}
        </div>
    )
}


export default GGProductItem