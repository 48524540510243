import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentItemUserPayments from "./PaymentItemUserPayments";
const PaymentItem = ({ item, sortedPayments, i }) => {
  let newDay = sortedPayments[i + 1]
    ? sortedPayments[i + 1].date == item.date
    : false;
  const navigate = useNavigate();

  const [userHover,setUserHover] = useState(false)
  return (
    <>
      <div
        className={`payment__item ${item.isOnline && "onlineGame"} ${
          item.duration === "forever" && "gameAccount"
        }`}
        id={item.login ? "codereceived" : `${item.title == 'TopUp' && 'TopUp'}`}
      >
        <p
          style={
            item.category == "streaming" || item.category == "netflix"
              ? { color: "rgba(255, 0, 0, 0.717)" }
              : item.category == "vpn"
              ? { color: "rgb(0, 255, 218)" }
              : {}
          }
        >
          {item.title}
          <span className="isUserReg">{item.isNewUser ? "new" : ""}</span>
        </p>
        <p
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm('ok?')) {
              navigate(`/users?email=${item.email}`);
            }
          }}
          className={item.login && "reg-user"}
          onMouseEnter={() => setUserHover(true)}
          id="payment-item__user-email"
        >
          {item.email}
          {userHover && <PaymentItemUserPayments userEmail={item.email}/> }
        </p>
        <h4>{item.date}</h4>
        <h4>{item.duration}</h4>
        <h4 className={item.amount == 0 && "free-game"}>
       {item.usedCashbackUsd && <span>({item.usedCashbackUsd})</span>}   {item.amount == 0 ? "FREE" : `${item.amount} ${item.currency}`}
        </h4>
      </div>
      {!newDay ? (
        <div className="new-day__block">
          <h3>{item.date}</h3>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaymentItem;
