import React from "react";
import { useSelector } from "react-redux";
import GameItem from "./GameItem.jsx";
import Loading from "./UI/LoadingAnim.jsx";
import ProductIncomeItem from "./Statistics/ProductIncomeItem.jsx";

const GamesList = ({ filteredGames, option }) => {
  const { isLoading } = useSelector((state) => state.game);

  if (isLoading) {
    return <Loading />;
  }

  return filteredGames.map((game) =>
    option == "income" ? (
      <ProductIncomeItem key={game._id} game={game} />
    ) : (
      <GameItem key={game._id} game={game} />
    )
  );
};

export default React.memo(GamesList);
