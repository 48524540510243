import React, { useEffect } from "react";
import { useState } from "react";
import {
  addLock,
  changeGameRating,
  changeOnlineStatus,
  update,
  changeGameTitle,
  changeGamePrice,
  changeGamePass,
  changeGameVisivility,
  changeGameLogin,
} from "../store/action-creators/gamesActions";
import { useDispatch } from "react-redux";
import axios from "axios";
import Loader from "./UI/Loader";

const GameItem = ({ game }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [copyAnimL, setCopyAnimL] = useState(false);
  const [copyAnim, setCopyAnim] = useState(false);
  const [copyAnimP, setCopyAnimP] = useState(false);
  

  const addL = (d) => {
    addLock(d, game.id);
    setVisibility(false);
    setTimeout(() => {
      setVisibility(true);
    }, 700);
    dispatch(update());
  };
  const [auth, setAuth] = useState({ login: "", pass: "" });
  const [gameModalVisibility, setGameModalVisibility] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [newLogin, setNewLogin] = useState("");

  const getAuth = async () => {
    axios
      .post("https://api.steam-rent.com/checkAuthData", {
        id: game.id,
        p: 7378,
      })
      .then((resp) => setAuth(resp.data));
  };

  const updatex = () => {
    setTimeout(() => {
      getAuth();
    }, 500);
  };

  // useEffect(() => {
  //   getAuth();
  //   setGameModalVisibility(true);
  // }, []);

  return (
    <>
      <div
        style={!visibility ? { filter: "blur(5px),brightness(70%)" } : {}}
        className={`game__item ${game.free && 'game__item_free'}`}
        id={open ? "open" : ""}
      >
        <div className="top">
          <div className="game__item__part">
            <p
              onClick={() =>
                dispatch(changeGameTitle(prompt("Enter new title"), game.id))
              }
            >
              {game.title}

              {game.free && <strong className="free-count"> {'>'} {game.freeCount}</strong>}
            </p>

            

            <p
              onClick={() =>
                dispatch(changeOnlineStatus(prompt("yes or no"), game.id))
              }
              style={
                game.isOnline
                  ? {}
                  : { opacity: "0.1", textDecoration: "line-through" }
              }
            >
              {" "}
              online
            </p>
          </div>
          <div className="game__item__part">
            <div
              className="game__item__part_sm"
              id={game.willBeAvilable > Date.now() ? "" : "hidden"}
            >
              <p>
                {Math.round((game.willBeAvilable - Date.now()) / 86400000)} days
              </p>

              <img
                onClick={() => addL(prompt("days?"), game.id)}
                src={require("../assets/img/lock.png")}
                alt=""
              />
            </div>
            <div
              style={{ justifyContent: "flex-end" }}
              className="game__item__part_sm"
            >
              <h4
                onClick={() =>
                  dispatch(changeGameRating(prompt("Enter new Rate"), game.id))
                }
              >
                Rate: {game.rate}
              </h4>
              <span
                style={open ? { transform: "rotate(270deg)" } : {}}
                onClick={() => setOpen(!open)}
                className="open_btn"
              >
                &#62;
              </span>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="price">
            <img
              onClick={() => {
                auth.login == "" ? getAuth() : console.log("x");
                setGameModalVisibility(!gameModalVisibility);
              }}
              src={require("../assets/img/keyicon.png")}
              style={{ width: "25px", height: "25px" }}
              alt=""
            />
            <span>price:</span>
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice("d", "U", prompt(), game.id, game.price)
                )
              }
            >
              {game.price.uahPerDay}₴
            </p>{" "}
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice("w", "U", prompt(), game.id, game.price)
                )
              }
            >
              {game.price.uahPerWeek}₴
            </p>{" "}
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice("m", "U", prompt(), game.id, game.price)
                )
              }
            >
              {game.price.uahPerMonth}₴
            </p>
          </div>
          <div className="price">
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice("d", "T", prompt(), game.id, game.price)
                )
              }
            >
              {game.price.rubPerDay}₸
            </p>{" "}
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice("w", "T", prompt(), game.id, game.price)
                )
              }
            >
              {game.price.rubPerWeek}₸
            </p>{" "}
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice("m", "T", prompt(), game.id, game.price)
                )
              }
            >
              {game.price.rubPerMonth}₸
            </p>
          </div>

          <div className="price">
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice(
                    "d",
                    "$",
                    prompt(),
                    game.id,
                    game.price,
                    game.dgsID,
                    game._id
                  )
                )
              }
            >
              {game.price.usdPerDay}$
            </p>{" "}
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice(
                    "w",
                    "$",
                    prompt(),
                    game.id,
                    game.price,
                    game.dgsID,
                    game._id
                  )
                )
              }
            >
              {game.price.usdPerWeek}$
            </p>{" "}
            <p
              onClick={() =>
                dispatch(
                  changeGamePrice(
                    "m",
                    "$",
                    prompt(),
                    game.id,
                    game.price,
                    game.dgsID,
                    game._id
                  )
                )
              }
            >
              {game.price.usdPerMonth}$
            </p>
            <img
              onClick={() => dispatch(changeGameVisivility(game.id))}
              src={require("../assets/img/invisible32.png")}
              style={game.visibility == false ? {} : { filter: "opacity(0.1)" }}
              className="gameVisibilityIcon"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        style={gameModalVisibility ? {} : { display: "none" }}
        className="game-item__auth"
      >
        {auth.login == "" ? (
          <Loader />
        ) : (
          <>
            <p>
              login:{" "}
              <span
                onClick={() => {
                  setCopyAnimL(true);
                  navigator.clipboard.writeText(auth.login);
                }}
                onAnimationEnd={() => setCopyAnimL(false)}
                id={!copyAnimL ? "" : "copyAnim"}
              >
                {auth.login}
              </span>
            </p>
            <p>
              pass:{" "}
              <span
                onClick={() => setCopyAnimP(true)}
                onAnimationEnd={() => {
                  setCopyAnimP(false);
                  navigator.clipboard.writeText(auth.pass);
                }}
                id={!copyAnimP ? "" : "copyAnim"}
              >
                {auth.pass}
              </span>
            </p>
            <div></div>
            <div v style={{ display: "flex", alignItems: "center" }}>
              <input
                value={newLogin}
                onChange={(e) => setNewLogin(e.target.value)}
                type="text"
                placeholder="login"
              />
              <strong
                style={newLogin.length < 5 ? { zIndex: -1, opacity: 0 } : {}}
                onClick={() => {
                  dispatch(changeGameLogin(newLogin, game.id));
                  updatex();
                }}
              >
                ok
              </strong>
            </div>
            <div v style={{ display: "flex", alignItems: "center" }}>
              <input
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                type="text"
                placeholder="pass"
              />
              <strong
                onClick={() => {
                  dispatch(changeGamePass(newPass, game.id));
                  updatex();
                }}
                style={newPass.length < 5 ? { zIndex: -1, opacity: 0 } : {}}
              >
                ok
              </strong>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(GameItem);
