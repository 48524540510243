import axios from "axios";
import { userActions } from "../reducers/userReducer";

export const fetchUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: userActions.FETCH_USERS });
      const users = await axios
        .post("https://api.steam-rent.com/getusers", {
          pass: localStorage.getItem("auth"),
        })
        .then((resp) => resp.data);
      dispatch({ type: userActions.FETCH_USERS_SUCCESS, payload: users });
    } catch (e) {
      dispatch({ type: userActions.FETCH_USERS_ERROR });
      console.log(e);
    }
  };
};
